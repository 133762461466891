<template>
   <v-col
    v-if="($vuetify.breakpoint.mdAndUp && modal.laptopCols>0) || ($vuetify.breakpoint.smAndDown && modal.mobileCols>0)"
      >
   </v-col>
</template>

<script>
export default {
   props: [
      'modal', 
      'customStyle',
      ],
}
</script>
